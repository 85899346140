.swapNew {
    width: 100%;
    padding: 40px;
    background-color: #ffffff0d;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .network__box {
        display: flex;
        flex-direction: row;
        gap: 50px;

        .network {
            display: flex;
            flex-direction: row;
            gap: 30px;
            align-items: center;
    
            &__panel {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                gap: 20px;
    
                label {
                    color: white;
                }
            }
        }
    }

    .sendAmountBox {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        label {
            color: white;
        }
    }

    .sendAddress {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        label {
            color: white;
        }
    }

    .swapBtn {
        width: 250px;

        a {
            padding: 10px;
            border-radius: 50px;
            text-transform: none;

            // &:hover {
            //     background-color: rgba($color: #ff1616, $alpha: 0.5);
            //     color: white;
            // }
        }
    }
}