
.footer {
    padding: 40px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 20px;
}
.footer h3{
    color:#fff;
    margin:0px;
    font-size:20px;
    font-weight: 500;
}
.row {
    display: flex;
}
.column {
    flex: 20%;
    padding: 10px;
}
.links ul{
    margin:0px;
    padding:0px;
}
.links li{
    list-style-type:none;
    padding: 20px 0px 0px 0px;
}
.links a{
    text-decoration:none;
    color:#676b7d;
    font-size:14px;
    transition: 0.3s;
}
.links a:hover{
    color:#fff;
}

@media(max-width: 1650px){
    .footer {
        padding: 35px;
    }
}

@media(max-width: 767px){
    .footer .row{
        flex-wrap: wrap;
    }
    .footer {
        padding: 30px 15px 0px;
    }
}
