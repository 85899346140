.main {
    padding: 50px;
    background-color: #271b4d;
    // background: url(../../images/main-bg-img.jpg) no-repeat;
    background-size: cover;
    // height: 100vh;
    display: flex;
    background-position: bottom right;
}
.main-content {
    width: calc(100vw - 360px);
    padding-left: 50px;
    margin-left: 360px;
}
.content-top-sec {
    height:calc(100vh - 436px);
    padding-top: 20px
}
.cstm-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.top-bar .cstm-row{
    align-items: center;
}
.cstm-btn {
    color: #fff;
    font-size: 21px;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
}
.top-right-column {
    display: flex;
}
.main-heading h1 {
    color: #fff;
    text-transform: uppercase;
    font-size: 46px;
    position: relative;
    margin: 0px;
}
.main-heading h1:after, .main-heading h1:before {
    content: "";
    background: #ff1616;
    background-position: 0px 0px;
    position: absolute;
    bottom: -3px;
    height: 2px;
    left: 0px;
    border-radius: 50px;
}
.main-heading h1:after {
    width: 93%;
}
.main-heading h1:before {
    width: 6px;
    right: 0px;
    left: auto;
}
.icon-btn.cstm-btn {
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.05);
    padding: 12px 20px;
    margin-right: 37px;
    background: rgba(255, 255, 255, 0.05);
    text-decoration: none;
}
.cstm-btn img, .connect-btn i, .cstm-btn svg {
    margin-right: 12px;
    font-size: 25px;
}
.cstm-btn svg {
    width: 67px;
    height: 35px;
}
.connect-btn.cstm-btn {
    background: #ff1616;
    border-radius: 10px;
    padding: 17px 25px;
    border: 1px solid #ff1616;
    text-decoration: none;
}
.cstm-btn{
    transition: 0.3s;
}
.cstm-btn:hover{
    background: rgba(0, 0, 0, 0.1);
    border-color: rgba(0, 0, 0, 0.1);
}
.connect-btn:hover .lni{
    color: #ff1616;
}

.chart-sec {
    padding: 30px 0px;
}



@media(max-width: 9999px){
    .main {
        height: 100%;
    }
    .content-top-sec {
        height: auto;
    }
}

@media(max-width: 1650px){
    .main-heading h1{
        font-size: 30px;
    }
    .main-heading h1:after{
        background-size: contain;
    }
    .main {
        /*padding: 30px 38px;*/
        padding:40px 50px;
    }
    .main-content {
        width: calc(100vw - 245px);
        margin-left: 245px;
    }
    .cstm-btn {
        font-size: 15px;
    }
    .icon-btn img {
        width: 54px;
    }
    .icon-btn.cstm-btn {
        padding:10px 13.5px;
    }
    .connect-btn.cstm-btn {
        padding: 10px 23px;
    }
    .connect-btn i {
        font-size: 20px;
    }
    .cstm-btn svg {
        width: 54px;
        height: 28px;
    }
}
@media(max-width: 1199px){
    .main-content {
        width: 100%;
        margin-left: 0px;
        padding-left: 0px;
    }
    .main {
        display: block;
    }
    .mbl-menu img {
        width: 180px;
    }
}

@media(max-width: 767px){
    .top-right-column {
        margin-top: 30px;
    }
    .content-top-sec {
        padding-top: 0;
    }
    .icon-btn.cstm-btn {
        margin-right: 15px;
    }
    .connect-btn.cstm-btn {
        padding: 12.5px 15px;
    }
}

@media(max-width: 991px){
    .main {
        padding: 30px 15px;
    }
    .content-top-sec {
        height:auto !important;
    }
}

@media (min-width: 768px) and (max-width: 991px){
    .content-top-sec {
        height: calc(100vh - 400px);
    }
    .connect-btn.cstm-btn {
        padding: 12.5px 20px;
    }
    .icon-btn.cstm-btn {
        margin-right: 15px;
    }
    .top-logo img {
        width: 250px;
    }
}
@media (min-width: 0px) and (max-width: 400px){
    .cstm-btn img, .connect-btn i, .cstm-btn svg {
        margin-right: 10px;
    }
    .connect-btn.cstm-btn, .icon-btn.cstm-btn {
        padding: 12.5px 10px;
    }
    .icon-btn.cstm-btn {
        margin-right: 10px;
    }
}
