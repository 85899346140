.inputText {
    outline: none;
    border: 1px solid #ff1616;
    background-color: transparent;
    padding: 14px 20px 14px 20px;
    color: white;
    border-radius: 50px;
}

.width-200 {
    width: 200px;
}

.width-400 {
    width: 400px;
}