.customSelect {
    color: #fff;
    border: 1px solid #ff1616;
    background-color: transparent;
    border-radius: 50px;
    padding: 14px;
    font-size: 16px;
    position: relative;
    /* background-position: 12px 6px !important; */
    outline: none;
    cursor: pointer;
    /* height: 30px; */
}