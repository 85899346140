.cstm-row.responsive-row{
    display: none;
}
.sidebar {
    padding: 50px 40px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 20px;
    display: block;
    position: fixed;
    top: 50px;
    /*bottom: 0;*/
    left: 0px;
    max-width: 360px;
    width: 100%;
    margin: 0px 0px 0px 50px;
    height: calc(100vh - 100px);
}
.side-menu {
    padding: 0px;
    height: calc(100% - 183px);
    margin-top: 50px;
    overflow-y: auto;
}
.side-menu li {
    list-style-type: none;
    margin-bottom: 12px;
    transition: 0.3s;
}
.side-menu li a:hover, .side-menu li a.active {
    background: rgba(0, 0, 0, 0.1);
}
.side-menu li a {
    color: #fff;
    text-decoration: none;
    position: relative;
    padding: 18px 44px 18px 44px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
        border-radius: 10px;
}
.side-menu li a svg {
    width: 25px;
    margin-right: 25px;
}
.side-menu li a:hover svg g, .side-menu li a.active svg g {
    fill: #ff1616;
}
.side-menu img {
    margin-right: 20px;
}
.social-icon{
    text-align: center;
}
.copy-right {
    color: #676b7d;
    font-size: 12px;
    text-align: center;
    font-weight: 700;
}
.cstm-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color:#222342;
}

::-webkit-scrollbar
{
    width: 10px; 
}

::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #484968;
}

.toggle-menu svg:nth-child(2), .toggle-menu.toggle-cross svg:first-child{
    display: none;
}
.toggle-menu.toggle-cross svg:nth-child(2){
    display: block;
}
.social-icon a {
    color: #767b86;
    margin: 0 6.5px;
    font-size: 16px;
    transition: 0.3s;
}
.social-icon a:hover {
    color: #ff1616;
}


@media(max-width: 1650px){
.side-menu li {
    font-size: 17px;   
    /*padding: 20px 35px;*/
    margin-bottom: 9px;
}
.side-menu li a{
    padding: 15px 25px;
    font-size: 16px;
}
.side-menu li a::before{
    left: 30px;
}
.side-menu {
    height: calc(100vh - 280px);
    margin-top: 25px;
}
.sidebar {
    padding: 30px;
    top: 35px;
    max-width: 245px;
    margin: 0px 0px 0px 50px;
    height: calc(100vh - 80px);
}
.main {
	padding:40px 50px;
}
.social-icon i:last-child {
    margin-right: 0px;
}
.social-icon {
    text-align: center;
}
.side-menu li a svg {
    width: 20px;
    margin-right: 15px;
}
}
@media(max-width: 1199px){
.sidebar {
    max-width: 0px;
    visibility: hidden;
    transition: 0.2s;
    padding: 35px 0px;
    top: 0px;
    overflow: hidden;
    height: 100vh;
}
.sidebar.width {
    max-width: 256px;
    visibility: visible;
    transition: 0.2s;
    padding: 35px 30px;
    top: 0px;
}
.sidebar {
    margin: 0px;
}
.cstm-row.responsive-row{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}
.sidebar{
    background: #1c1c42;
    z-index: 9;
}
.mbl-menu img {
    width: 180px;
}
.side-menu {
    height: calc(100vh - 280px);
}
}
